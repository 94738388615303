import {Box, Center, Table, TableContainer, Tbody, Td, Text, Tr, VStack, Link} from '@chakra-ui/react';

import fiveStandLeagueInfoContent from './five-stand-league-info.json';


export const FiveStandLeagueInfo = () => {
  return (
    <div>
        <iframe width="850"
                height="1100"
                src={fiveStandLeagueInfoContent.content.main}
                title={'Five Stand League Flyer'} />
    </div>
  );
};
