import {Box, Center, Table, List, Heading, TableContainer, Tbody, Td, Text, Tr, VStack, Link} from '@chakra-ui/react';

import trapLeagueResultsContent from './trap-league-results.json';


export const TrapLeagueResults = () => {
  return (
      <iframe width="850"
              height="1100"
              src={trapLeagueResultsContent.content.main}
              title={'Trap League Results'} />
  );
};
