import {Box, Center, Table, TableContainer, Tbody, Td, Text, Tr, VStack, Link} from '@chakra-ui/react';

import { ButtonCallToAction } from '../../Components';

import matchesCompetitionContent from './matches-competition.json';

const { additional } = matchesCompetitionContent;

export const MatchesCompetition = () => {
  return (
    <Box mt={6}>
      <Center>
        <Text as="h3" color="#000000" fontSize={{ base: '22px' }} fontWeight="600">{additional.title}</Text>
      </Center>
      <Box pb="6">Questions about our Match Programs? Contact us at <Link href="mailto:competitions@goldengunclub.us">competitions@goldengunclub.us</Link> and our MDs will reach out.</Box>
      <Box>Match Directors:</Box>
      <TableContainer my="16px">
        <Table size='sm'>
          <Tbody>
            <Tr>
              <Td>Black Powder Cartridge</Td>
              <Td>Mike Rix</Td>
            </Tr>
            <Tr>
              <Td>Cowboy Silhouette</Td>
              <Td>Rich DelPizzo</Td>
            </Tr>
            <Tr>
              <Td>Service Rifle</Td>
              <Td>Matt Anthony</Td>
            </Tr>
            <Tr>
              <Td>Bowling Pin Pistol</Td>
              <Td>Craig Smith</Td>
            </Tr>
            <Tr>
              <Td>NRA Action Pistol</Td>
              <Td>Jim Flagg</Td>
            </Tr>
            <Tr>
              <Td>Skeet, 5-stand and Trap Leagues</Td>
              <Td>Jason Nester</Td>
            </Tr>
          </Tbody>
        </Table>
      </TableContainer>
      <VStack mt={4} spacing={8}>
        {additional.ctas.map((cta) => (
          <ButtonCallToAction
            image={cta.image}
            title={cta.title}
            subtitle={cta.subtitle}
            buttonLink={cta.buttonLink}
            buttonText={cta.buttonText}
          />
        ))}
      </VStack>
    </Box>
  );
};
