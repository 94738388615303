import {Box, Center, Table, List, Heading, TableContainer, Tbody, Td, Text, Tr, VStack, Link} from '@chakra-ui/react';

import trapLeagueInfoContent from './trap-league-info.json';


export const TrapLeagueInfo = () => {
  return (
      <iframe width="850"
              height="1100"
              src={trapLeagueInfoContent.content.main}
              title={'Trap League Flyer'} />
  );
};
