import { Box, Tabs, TabList, TabPanels, Tab, TabPanel } from '@chakra-ui/react';

import { Footer, WithSubnavigation } from '../../Components';
import { Announcements, Members, Newsletters } from './Pages';

export const Admin = () => {
  return (
    <Box bg="#F7F7F7">
      {WithSubnavigation()}
      <Box pt="84px" px={{ base: '42px', md: '84px' }}>
        <Tabs mt={4}>
          <TabList>
            <Tab>Announcements</Tab>
            <Tab>Board Minutes & Notes</Tab>
            <Tab>Members</Tab>
          </TabList>
          <TabPanels>
            <TabPanel>
              <Announcements />
            </TabPanel>
            <TabPanel>
              <Newsletters />
            </TabPanel>
            <TabPanel>
              <Members />
            </TabPanel>
          </TabPanels>
        </Tabs>
      </Box>
      <Footer />
    </Box>
  )
};
