import { Navigate, Route, Routes } from 'react-router-dom';

import { useEffect, useState } from 'react';

import { AboutUs, Admin, Calendar, Home, Main, MatchesCompetition, Membership, RangeCamera, Newsletters, Visitors, FAQ } from '../../Pages';
import { FiveStandLeagueInfo } from '../../Pages/MatchesCompetition/FiveStandLeague/Info';
import { FiveStandLeagueResults } from '../../Pages/MatchesCompetition/FiveStandLeague/Results';
import { TrapLeagueInfo } from '../../Pages/MatchesCompetition/TrapLeague/Info';
import { TrapLeagueResults } from '../../Pages/MatchesCompetition/TrapLeague/Results';

import aboutUsContent from '../../Pages/AboutUs/about-us.json';
import blackPowderSilhouetteContent from '../../Pages/MatchesCompetition/BlackPowderSilhouette/black-powder-silhouette.json';
import bowlingPinContent from '../../Pages/MatchesCompetition/BowlingPin/bowling-pin.json';
import cowboySilhouetteContent from '../../Pages/MatchesCompetition/CowboySilhouette/cowboy-silhouette.json';
import calendarContent from '../../Pages/Calendar/calendar.json';
import cmpHighpowerContent from '../../Pages/MatchesCompetition/CmpHighpower/cmp-highpower.json';
import facilitiesContent from '../../Pages/Facilities/facilities.json';
import fiveStandLeagueContent from '../../Pages/MatchesCompetition/FiveStandLeague/five-stand-league.json';
import fiveStandLeagueInfoContent from '../../Pages/MatchesCompetition/FiveStandLeague/Info/five-stand-league-info.json';
import fiveStandLeagueResultsContent from '../../Pages/MatchesCompetition/FiveStandLeague/Results/five-stand-league-results.json';
import matchesCompetitionContent from '../../Pages/MatchesCompetition/matches-competition.json';
import membershipContent from '../../Pages/Membership/membership.json';
import newslettersContent from '../../Pages/Newsletters/newsletters.json';
import notFoundContent from '../../Pages/NotFound/not-found.json';
import nraActionPistol from '../../Pages/MatchesCompetition/NRAActionPistol/nra-action-pistol.json';
import rangeRulesContent from '../../Pages/Membership/RangeRules/range-rules.json';
import rangeCameraContent from '../../Pages/RangeCamera/range-camera.json';
import faqContent from '../../Pages/FAQ/faq.json';
import riflePistolContent from '../../Pages/Facilities/RiflePistol/rifle-pistol.json';
import shotgunContent from '../../Pages/Facilities/Shotgun/shotgun.json';
import trapLeagueContent from '../../Pages/MatchesCompetition/TrapLeague/trap-league.json';
import trapLeagueInfoContent from '../../Pages/MatchesCompetition/TrapLeague/Info/trap-league-info.json';
import trapLeagueResultsContent from '../../Pages/MatchesCompetition/TrapLeague/Results/trap-league-results.json';
import visitorsContent from '../../Pages/Visitors/visitors.json';

import { useUser } from '../../Context';

export const AppRoutes = () => {
  const { user } = useUser();

  const [isAdmin, setIsAdmin] = useState(false);

  const groups = user?.signInUserSession?.accessToken?.payload?.['cognito:groups'];

  useEffect(() => {
    setIsAdmin(groups?.includes('admins') || groups?.includes('match_directors'));
  }, [user]);

  return (
    <Routes>
      <Route path="/" element={<Home />} />

      {isAdmin && (
        <Route path="/admin" element={<Admin />} />
      )}

      <Route path="facilities">
        <Route index element={
          <Main hasSidebar={true} pageContent={facilitiesContent} />
        } />
        <Route path="shotgun" element={
          <Main hasSidebar={true} pageContent={shotgunContent} />}
        />
        <Route path="rifle-pistol" element={
          <Main hasSidebar={true} pageContent={riflePistolContent} />
        } />
      </Route>

      <Route path="visitors" element={
        <Main pageContent={visitorsContent}>
          <Visitors />
        </Main>
      } />

      <Route path="membership">
        <Route index element={
          <Main hasSidebar={true} pageContent={membershipContent}>
            <Membership />
          </Main>
        } />
        <Route path="range-rules" element={
          <Main hasSidebar={true} pageContent={rangeRulesContent} />
        } />
      </Route>

      <Route path="matches-competition">
        <Route index element={
          <Main hasSidebar={true} pageContent={matchesCompetitionContent}>
            <MatchesCompetition />
          </Main>
        } />
        <Route path="cowboy-silhouette" element={
          <Main hasSidebar={true} pageContent={cowboySilhouetteContent} />
        } />
        <Route path="cmp-highpower-service-rifle" element={
          <Main hasSidebar={true} pageContent={cmpHighpowerContent} />
        } />
        <Route path="bowling-pin-match" element={
          <Main hasSidebar={true} pageContent={bowlingPinContent} />
        } />
        <Route path="black-powder-silhouette" element={
          <Main hasSidebar={true} pageContent={blackPowderSilhouetteContent} />
        } />
        <Route path="nra-action-pistol" element={
          <Main hasSidebar={true} pageContent={nraActionPistol} />
        } />
        <Route path="trap-league">
          <Route index element={
            <Main hasSidebar={true} pageContent={trapLeagueContent} />
          } />
          <Route path="trap-league-info" element={
            <Main hasSidebar={true} pageContent={trapLeagueInfoContent}>
              <TrapLeagueInfo />
            </Main>
          } />
          <Route path="trap-league-results" element={
            <Main hasSidebar={true} pageContent={trapLeagueResultsContent}>
              <TrapLeagueResults />
            </Main>
          } />
        </Route>
        <Route path="five-stand-league">
          <Route index element={
            <Main hasSidebar={true} pageContent={fiveStandLeagueContent} />
          } />
          <Route path="five-stand-league-info" element={
            <Main hasSidebar={true} pageContent={fiveStandLeagueInfoContent}>
              <FiveStandLeagueInfo />
            </Main>
          } />
          <Route path="five-stand-league-results" element={
            <Main hasSidebar={true} pageContent={fiveStandLeagueResultsContent}>
              <FiveStandLeagueResults />
            </Main>
          } />
        </Route>
      </Route>

      <Route path="about-us"> 
        <Route index element={
          <Main hasSidebar={true} pageContent={aboutUsContent}>
            <AboutUs />
          </Main>
        } />
        <Route path="calendar" element={
          <Main hasSidebar={true} pageContent={calendarContent}>
            <Calendar />
          </Main>
        } />
        <Route path="board-minutes-notes" element={
          <Main hasSidebar={true} pageContent={newslettersContent}>
            <Newsletters />
          </Main>
        } />
        <Route path="range-camera" element={
          <Main hasSidebar={true} pageContent={rangeCameraContent}>
            <RangeCamera />
          </Main>
        } />
        <Route path="faq" element={
          <Main hasSidebar={true} pageContent={faqContent}>
            <FAQ />
          </Main>
        } />
      </Route>

      <Route path="index.html" element={
        <Navigate to="/" />
      } />

      <Route path="*" element={
        <Main pageContent={notFoundContent} />
      } />
    </Routes>
  )
};
