import {Box, Center, Table, List, Heading, TableContainer, Tbody, Td, Text, Tr, VStack, Link} from '@chakra-ui/react';

import fiveStandLeagueResultsContent from './five-stand-league-results.json';


export const FiveStandLeagueResults = () => {
  return (
      <iframe width="850"
              height="1100"
              src={fiveStandLeagueResultsContent.content.main}
              title={'Five Stand League Results'} />
  );
};
