import {Box, Center, Flex, Link, Text} from '@chakra-ui/react';

import { CallToAction } from '../../Components';

import membershipContent from './membership.json';

const { additional } = membershipContent;
const { applications, amenities, types } = additional;

export const Membership = () => {
  return (
    <Box>
      <Center>
        <Text as="h3" color="#000000" fontSize={{ base: '22px' }} fontWeight="600">{applications.title}</Text>
      </Center>
      <Center mt={6}>
        <Link href={applications.new_members} isExternal bg="#FD5521" color="#FFF" p="22px 32px" fontWeight="600" borderRadius="6px">New Member Application</Link>
      </Center>
      <Center mt={6}>
        <Text>Membership Renewals are open for existing members, for the 2025 membership year! Please click below to submit your renewal; You can then make your payment by Credit Card over the phone by calling the Club House at 303-366-6970 or you can come in and pay at the Club House in person during business hours. (Tue-Sun, 10AM to 4PM) (After Daylight savings we switch to our summer hours where we will be open until at least 6PM on Tuesdays, Wednesdays, and Thursdays)</Text>
      </Center>
      <Center>
          <Link href={applications.renewal} isExternal bg="#FD5521" color="#FFF" p="22px 32px" fontWeight="600" borderRadius="6px">Member Renewal Application for 2025</Link>
      </Center>
      <Center>
        <Text as="h3" color="#000000" fontSize={{ base: '22px' }} fontWeight="600">{types.title}</Text>
      </Center>

      <Center>
        <Text as="p">""</Text>
      </Center>
      <Center>
        <Text as="p">{types.subtitle}</Text>
      </Center>
      {types.ctas.length > 0 && (
        <Flex flexDirection={{ base: 'column', md: 'row' }}>
          {types.ctas.map((cta) => (
            <CallToAction main={cta.main} image={cta.image} title={cta.title} />
          ))}
        </Flex>
      )}

      <Center>
        <Text as="p">""</Text>
      </Center>
      <Center>
        <Text as="h2" color="#000000" fontSize={{ base: '22px' }} fontWeight="600">{amenities.title}</Text>
      </Center>
      <Center>
        <Text as="p">{amenities.subtitle}</Text>
      </Center>
      {amenities.ctas.length > 0 && (
        <Flex flexDirection={{ base: 'column', md: 'row' }}>
          {amenities.ctas.map((cta, index) => index < 3 && (
            <CallToAction main={cta.main} image={cta.image} title={cta.title} />
          ))}
        </Flex>
      )}
      {amenities.ctas.length > 3 && (
        <Flex flexDirection={{ base: 'column', md: 'row' }}>
          {amenities.ctas.map((cta, index) => index > 2 && (
            <CallToAction main={cta.main} image={cta.image} title={cta.title} />
          ))}
        </Flex>
      )}
    </Box>
  )
};
